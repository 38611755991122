import commonHelper from '@/app/utility/common.helper.utility';
import { ModelSelect } from 'vue-search-select';
import AddValueset from './addValueset';
export default {
  name: 'ValueSet',
  components: {
    ModelSelect,
    AddValueset
  },
  watch: {
    currentPage: function() {
      this.getValueSetList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getValueSetList();
    }
  },
  props: ['showValuesetMaster'],
  data() {
    return {
      unsubscribe: null,
      viewMode: false,
      setTime: null,
      loader: false,
      payload: {},
      showAddLookupModal: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      valueSetName: null,
      currentPage: 1,
      totalRows: null,
      selectedValueType: {
        value: null,
        text: null
      },
      valueTypeList: [],
      valueSetList: [],
      valueSetFields: [
        {
          key: 'value_set_name'
        },
        {
          key: 'value_set_type',
          label: 'Type'
        },
        {
          key: 'description'
        }
      ]
    };
  },
  mounted() {
    this.getValueSetType();
    this.getValueSetList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.viewMode = false;
          this.showAddLookupModal = true;
          this.valusetHdrId = null;
        }
        if (actionName === 'download' && !this.showAddLookupModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'valueset/getValueSetList',
            'valueset',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getValueSetList() {
      this.loader = true;
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        value_set_name: this.valueSetName,
        value_set_type_lookup: this.selectedValueType.value
      };
      this.$store
        .dispatch('valueset/getValueSetList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.valueSetList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.perPage = commonHelper.perPageRecord;
      this.getValueSetList();
    },
    getValueSetType() {
      this.$store
        .dispatch('shared/getDataFromLookupsByLookupType', 'VALUE_SET_TYPE ')
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data;
            const valueSet = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.dtl_description
              };
            });
            this.valueTypeList = valueSet;
          }
        });
    },
    rowSelected(item) {
      if (this.showValuesetMaster) {
        this.$emit('selectValueSetDetail', item);
      } else {
        this.viewMode = true;
        this.showAddLookupModal = true;
        this.valusetHdrId = item.value_set_hdr_id;
      }
    },
    hideAddLookUpModal() {
      this.showAddLookupModal = false;
    },
    clearSearh() {
      this.valueSetName = null;
      this.selectedValueType = {
        value: null,
        text: null
      };
      this.getValueSetList();
    }
  },
  destroyed() {
    clearTimeout(this.setTime);
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
