import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
// import { format } from 'date-fns';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'AddValueset',
  components: {
    ModelSelect,
    DatePicker
  },
  props: ['valusetHdrId'],
  watch: {
    selectedValueType: function() {
      this.value_Set_Details.value_set_type_lookup = this.selectedValueType.value;
    },
    currentPage: function() {
      this.getValueSetList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getValueSetList();
    },
    currentPage1: function() {
      this.getValueSetByIdPage(this.valusetHdrId);
    },
    perPage1: function() {
      this.currentPage1 = 1;
      this.getValueSetByIdPage(this.valusetHdrId);
    }
  },
  data() {
    return {
      unsubscribe: null,
      querySelectionOptions: [],
      detailIndex: null,
      parentValueSetModal: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      pageOptions1: commonHelper.getPageOption(),
      perPage1: commonHelper.perPageRecord,
      currentPage1: 1,
      totalRows: null,
      totalRows1: null,
      dependentModal: false,
      showAlert: false,
      showAddLookupModal: true,
      viewMode: false,
      showSelectQueryAlert: false,
      queryRespMessage: null,
      isSuccess: false,
      responseMsg: null,
      loader: false,
      valueSetName: null,
      dependentObj: {},
      selectedOwner: {
        value: 'User',
        text: 'User'
      },
      ownerList: [
        {
          value: 'User',
          text: 'User'
        },
        {
          value: 'System',
          text: 'System'
        },
        {
          value: 'Flexfield',
          text: 'Flexfield'
        }
      ],
      valueSetDetails: {
        description: null,
        from: '',
        key: '',
        order_by: '',
        other_flag: false,
        owner: null,
        parent_value_set_id: 0,
        row_limit: 0,
        select: '',
        value: '',
        value_set_hdr: '',
        value_set_header: '',
        value_set_hdr_id: 0,
        value_set_name: '',
        value_set_type_lookup: '',
        where: '',
        valueset_details: [
          {
            display_flag: false,
            mandatory_flag: false,
            parent_value_set_line_id: 0,
            parent_code: null,
            sequence: null,
            value_code: null,
            where_cond: '',
            value_desc: '',
            value_end_date: '',
            value_meaning: '',
            start_date_end_date: [],
            value_set_line_id: 0,
            value_set_where_id: 0,
            value_start_date: '',
            where_column: '',
            where_parameter: '',
            where_sequence: 0
          }
        ]
      },
      value_Set_Details: {
        description: null,
        from: '',
        key: '',
        order_by: '',
        other_flag: false,
        owner: null,
        parent_value_set_id: 0,
        row_limit: 0,
        select: '',
        value: '',
        value_set_hdr: '',
        value_set_header: '',
        value_set_hdr_id: 0,
        value_set_name: '',
        value_set_type_lookup: '',
        where: ''
      },
      initialValueSetDtls: [],
      valueSetDtls: [
        {
          display_flag: false,
          mandatory_flag: false,
          parent_value_set_line_id: 0,
          parent_code: null,
          sequence: null,
          value_code: null,
          where_cond: '',
          value_desc: '',
          value_end_date: '',
          value_meaning: '',
          // start_date_end_date: [],
          start_date: null,
          end_date: null,
          value_set_line_id: 0,
          value_set_where_id: 0,
          // value_start_date: '',
          value_start_date: format(new Date(), 'dd-MMM-yyyy'),
          where_column: '',
          where_parameter: '',
          where_sequence: 0
        }
      ],
      tableQueryForm: {
        query_select: null,
        query_from: null,
        query_where: null,
        query_orderby: null,
        query_key: null,
        query_value: null
      },
      editMode: false,
      selectedValueType: {
        value: null,
        text: null
      },
      valueTypeList: [],
      valueSetList: [],
      IndValueSetFields: [
        {
          key: 'value_code'
        },
        {
          key: 'value_meaning'
        },
        {
          key: 'value_desc'
        },
        // {
        //   key: 'start_date_end_date',
        //   label: 'Start Date - End Date'
        // },
        {
          key: 'value_start_date',
          label: 'Start Date'
        },
        {
          key: 'value_end_date',
          label: 'End Date'
        },
        {
          key: 'sequence'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      DepValueSetFields: [
        {
          key: 'value_code'
        },
        {
          key: 'value_meaning'
        },
        {
          key: 'value_desc'
        },
        {
          key: 'parent_value_set_id',
          label: 'Parent Code'
        },
        // {
        //   key: 'start_date_end_date',
        //   label: 'Start Date - End Date'
        // },
        {
          key: 'value_start_date',
          label: 'Start Date'
        },
        {
          key: 'value_end_date',
          label: 'End Date'
        },
        {
          key: 'sequence'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      TableValueSetFields: [
        {
          key: 'where_column',
          label: 'Column Name'
        },
        {
          key: 'where_parameter',
          label: 'Parameter'
        },
        {
          key: 'where_cond',
          label: 'Condition'
        },
        {
          key: 'where_sequence',
          label: 'Sequence'
        },
        {
          key: 'mandatory_flag'
        },
        {
          key: 'display_flag'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      valueSetFields: [
        {
          key: 'value_set_name'
        },
        {
          key: 'value_set_type',
          label: 'Type'
        },
        {
          key: 'description',
          class: 'text-center'
        }
      ],
      parentValueSetList: [],
      parentValueFields: [
        {
          key: 'value_code'
        },
        {
          key: 'value_meaning'
        },
        {
          key: 'value_desc'
        }
      ],
      specIndex: null
    };
  },
  mounted() {
    // if(!this.editMode){
    //   this.getValueSetType();
    // }
    if (this.valusetHdrId) {
      this.editMode = true;
      // this.getValueSetDetailById(this.valusetHdrId);
      this.getValueSetByHdrDtls(this.valusetHdrId);
    } else {
      this.getValueSetType();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEditValueSet();
        } else if (actionName === 'update') {
          this.editMode = false;
          if (this.value_Set_Details.value_set_type_lookup === 'TABLE') {
            const removeSelect = this.tableQueryForm.query_select
              .toLowerCase()
              .replace('select', '');
            const tempQuerySelectOptn = removeSelect.trim().split(',');
            this.querySelectionOptions = tempQuerySelectOptn.map(key => {
              return key.slice(key.lastIndexOf(' ') + 1);
            });
          }
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', {
            id: this.value.value_set_hdr_id
          });
        }
      }
    });
  },
  methods: {
    addEditValueSet() {
      if (!this.editMode) {
        const checkStartDateNull = this.valueSetDtls.filter(
          data => !data.value_start_date
        );
        if (
          checkStartDateNull.length &&
          this.value_Set_Details.value_set_type_lookup !== 'TABLE'
        ) {
          alert('Please Select Start Date');
        } else {
          // const detailsArr = this.valueSetDtls.map(detail => {
          //   detail.value_start_date = commonHelper.formattedDate(
          //     detail.start_date
          //   );
          //   detail.value_end_date = commonHelper.formattedDate(detail.end_date);
          //   return detail;
          // });
          const addEditData = [];
          for (let i = 0; i < this.valueSetDtls.length; i++) {
            if (
              this.valueSetDtls[i].value_set_line_id !== 0 &&
              this.valueSetDtls[i].value_set_line_id ===
                this.initialValueSetDtls[i].value_set_line_id
            ) {
              if (
                this.valueSetDtls[i].parent_code !==
                  this.initialValueSetDtls[i].parent_code ||
                this.valueSetDtls[i].value_code !==
                  this.initialValueSetDtls[i].value_code ||
                this.valueSetDtls[i].value_desc !==
                  this.initialValueSetDtls[i].value_desc ||
                this.valueSetDtls[i].value_start_date !==
                  this.initialValueSetDtls[i].value_start_date ||
                this.valueSetDtls[i].value_end_date !==
                  this.initialValueSetDtls[i].value_end_date ||
                this.valueSetDtls[i].value_meaning !==
                  this.initialValueSetDtls[i].value_meaning ||
                this.valueSetDtls[i].sequence !==
                  this.initialValueSetDtls[i].sequence
              ) {
                addEditData.push(this.valueSetDtls[i]);
              }
            } else if (this.valueSetDtls[i].value_set_line_id == 0) {
              addEditData.push(this.valueSetDtls[i]);
            }
          }
          const payload = {
            description: this.value_Set_Details.description,
            owner: this.selectedOwner.value,
            parent_value_set_id: this.value_Set_Details.parent_value_set_id,
            value_set_hdr: this.value_Set_Details.value_set_hdr,
            value_set_hdr_id: this.value_Set_Details.value_set_hdr_id,
            value_set_name: this.value_Set_Details.value_set_name,
            value_set_type_lookup: this.selectedValueType.value,
            other_flag: this.value_Set_Details.other_flag,
            row_limit: this.value_Set_Details.row_limit,
            select: this.tableQueryForm.query_select,
            value: this.tableQueryForm.query_value,
            from: this.tableQueryForm.query_from,
            key: this.tableQueryForm.query_key,
            order_by: this.tableQueryForm.query_orderby,
            where: this.tableQueryForm.query_where,
            // valueset_details: this.valueSetDtls
            valueset_details:
              this.value_Set_Details.value_set_type_lookup === 'TABLE'
                ? this.valueSetDtls
                : addEditData.length
                ? addEditData
                : null
          };
          this.loader = true;
          this.$store
            .dispatch('valueset/addEditValueSet', payload)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.editMode = true;
                this.$emit('updateVsetList');
                this.isSuccess = true;
                this.responseMsg = response.data.message;
                this.value_Set_Details.value_set_hdr_id =
                  response.data.data.value_set_hdr_id;
                this.value_Set_Details.owner = response.data.data.owner;
                this.value_Set_Details.value_set_type =
                  response.data.data.value_set_type;
                this.getValueSetByIdPage(this.valusetHdrId);
              } else {
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.isSuccess = false;
              this.responseMsg = 'something went wrong.';
            });
        }
      }
    },
    // disabledDates(date) {
    //   return commonHelper.disabledDates(date);
    // },
    endDateIndex(index) {
      this.specIndex = index;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.valueSetDtls[this.specIndex].value_start_date
      );
    },

    // getValueSetDetailById(hdrId) {
    //   this.loader = true;
    //   this.$store
    //     .dispatch('valueset/getValueSetById', hdrId)
    //     .then(response => {
    //       this.loader = false;
    //       if (response.status === 200) {
    //         this.valueSetDetails = response.data.data;
    //         this.valueSetDetails.value_set_hdr =
    //           response.data.data.value_set_header;
    //         this.valueSetDetails.parent_value_set_id =
    //           response.data.data.parent_value_set_id;
    //         this.valueSetDetails.other_flag = response.data.data.others_flag;
    //         this.tableQueryForm.query_select = this.valueSetDetails.value_set_select;
    //         this.tableQueryForm.query_from = this.valueSetDetails.value_set_from;
    //         this.tableQueryForm.query_where = this.valueSetDetails.value_set_where;
    //         this.tableQueryForm.query_orderby = this.valueSetDetails.value_set_order_by;
    //         this.tableQueryForm.query_key = this.valueSetDetails.value_set_query_key;
    //         this.tableQueryForm.query_value = this.valueSetDetails.value_set_query_value;
    //         this.selectedValueType = {
    //           value: this.valueSetDetails.value_set_type_lookup,
    //           text: this.valueSetDetails.value_set_type
    //         };
    //         const vsetDetails = response.data.data.valueset_details.map(
    //           detail => {
    //             return {
    //               display_flag: detail.display_flag,
    //               mandatory_flag: detail.mandatory_flag,
    //               value_set_line_id: detail.value_set_dtl_id,
    //               parent_value_set_line_id: detail.parent_value_set_line_id,
    //               parent_code: detail.parent_code,
    //               sequence: detail.seq_order,
    //               value_code: detail.value_code,
    //               where_cond: detail.where_condition,
    //               value_desc: detail.value_desc,
    //               value_end_date: detail.end_date,
    //               value_meaning: detail.value_meaning,
    //               value_set_where_id: detail.value_set_where_id,
    //               value_start_date: detail.start_date,
    //               where_column: detail.where_column,
    //               where_parameter: detail.where_parameter,
    //               where_sequence: detail.where_seq
    //             };
    //           }
    //         );
    //         this.valueSetDetails.valueset_details = vsetDetails.map(detail => {
    //           detail.start_date_end_date = [
    //             new Date(detail.value_start_date),
    //             new Date(detail.value_end_date)
    //           ];
    //           return detail;
    //         });
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    getValueSetDetailById(valueSetHdrId) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        valueSetHdrId: valueSetHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('valueset/getValueSetById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.valueSetDetails = response.data.data;
            this.valueSetDetails.value_set_hdr =
              response.data.data.value_set_header;
            this.valueSetDetails.parent_value_set_id =
              response.data.data.parent_value_set_id;
            this.valueSetDetails.other_flag = response.data.data.others_flag;
            this.tableQueryForm.query_select = this.valueSetDetails.value_set_select;
            this.tableQueryForm.query_from = this.valueSetDetails.value_set_from;
            this.tableQueryForm.query_where = this.valueSetDetails.value_set_where;
            this.tableQueryForm.query_orderby = this.valueSetDetails.value_set_order_by;
            this.tableQueryForm.query_key = this.valueSetDetails.value_set_query_key;
            this.tableQueryForm.query_value = this.valueSetDetails.value_set_query_value;
            this.selectedValueType = {
              value: this.valueSetDetails.value_set_type_lookup,
              text: this.valueSetDetails.value_set_type
            };
            const vsetDetails = response.data.data.valueset_details.map(
              detail => {
                return {
                  display_flag: detail.display_flag,
                  mandatory_flag: detail.mandatory_flag,
                  value_set_line_id: detail.value_set_dtl_id,
                  parent_value_set_line_id: detail.parent_value_set_line_id,
                  parent_code: detail.parent_code,
                  sequence: detail.seq_order,
                  value_code: detail.value_code,
                  where_cond: detail.where_condition,
                  value_desc: detail.value_desc,
                  value_end_date: detail.end_date,
                  value_meaning: detail.value_meaning,
                  value_set_where_id: detail.value_set_where_id,
                  value_start_date: detail.start_date,
                  where_column: detail.where_column,
                  where_parameter: detail.where_parameter,
                  where_sequence: detail.where_seq
                };
              }
            );
            this.valueSetDetails.valueset_details = vsetDetails.map(detail => {
              detail.start_date_end_date = [
                new Date(detail.value_start_date),
                new Date(detail.value_end_date)
              ];
              return detail;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getValueSetByHdrDtls(valueSetHdrId) {
      const payload = {
        valueSetHdrId: valueSetHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('valueset/getValueSetByHdrDtls', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.value_Set_Details = response.data.data;
            this.value_Set_Details.value_set_hdr =
              response.data.data.value_set_hdr;
            this.value_Set_Details.parent_value_set_id =
              response.data.data.parent_value_set_id;
            this.value_Set_Details.other_flag = response.data.data.others_flag;
            this.tableQueryForm.query_select = this.value_Set_Details.value_set_select;
            this.tableQueryForm.query_from = this.value_Set_Details.value_set_from;
            this.tableQueryForm.query_where = this.value_Set_Details.value_set_where;
            this.tableQueryForm.query_orderby = this.value_Set_Details.value_set_order_by;
            this.tableQueryForm.query_key = this.value_Set_Details.value_set_query_key;
            this.tableQueryForm.query_value = this.value_Set_Details.value_set_query_value;
            this.selectedValueType = {
              value: this.value_Set_Details.value_set_type_lookup,
              text: this.value_Set_Details.value_set_type
            };
            this.getValueSetByIdPage(valueSetHdrId);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getValueSetByIdPage(valueSetHdrId) {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        valueSetHdrId: valueSetHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('valueset/getValueSetByIdPage', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const vsetDetails = response.data.data.page.map(detail => {
              return {
                display_flag: detail.display_flag,
                mandatory_flag: detail.mandatory_flag,
                value_set_line_id: detail.value_set_dtl_id,
                parent_value_set_line_id: detail.parent_value_set_line_id,
                parent_code: detail.parent_code,
                sequence: detail.seq_order,
                value_code: detail.value_code,
                where_cond: detail.where_condition,
                value_desc: detail.value_desc,
                value_end_date: detail.end_date,
                value_meaning: detail.value_meaning,
                value_set_where_id: detail.value_set_where_id,
                value_start_date: detail.start_date,
                where_column: detail.where_column,
                where_parameter: detail.where_parameter,
                where_sequence: detail.where_seq
              };
            });
            // this.valueSetDtls = vsetDetails.map(detail => {
            //   detail.start_date_end_date = [
            //     new Date(detail.value_start_date),
            //     new Date(detail.value_end_date)
            //   ];
            //   return detail;
            // });
            this.initialValueSetDtls = JSON.parse(JSON.stringify(vsetDetails));
            this.valueSetDtls = vsetDetails;
            this.totalRows1 = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getValueSetType() {
      this.loader = true;
      this.$store
        .dispatch('shared/getDataFromLookupsByLookupType', 'VALUE_SET_TYPE ')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            const valueSet = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.dtl_description
              };
            });
            this.valueTypeList = valueSet;
            this.selectedValueType = valueSet[0];
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (!this.editMode) {
        this.valueSetDtls.push({
          display_flag: false,
          mandatory_flag: false,
          parent_value_set_line_id: 0,
          parent_code: null,
          sequence: null,
          value_code: null,
          where_cond: '',
          value_desc: '',
          value_end_date: '',
          value_meaning: '',
          value_set_line_id: 0,
          value_set_where_id: 0,
          // start_date_end_date: [],
          start_date: null,
          end_date: null,
          // value_start_date: '',
          value_start_date: format(new Date(), 'dd-MMM-yyyy'),
          where_column: '',
          where_parameter: '',
          where_sequence: null
        });
      }
    },
    removeRow(index) {
      if (!this.editMode) {
        this.valueSetDtls.splice(index, 1);
      }
    },
    veryfQuery() {
      // const payload = {
      //   query_select: 'SELECT  FMS_PRJ_ID,FMS_PRJ_NAME,FMS_PRJ_CODE',
      //   query_from: 'FROM fms.fms_project',
      //   query_where: 'Where 1=1',
      //   query_orderby: 'ORDER BY FMS_PRJ_NAME',
      //   query_key: 'FMS_PRJ_ID',
      //   query_value: 'FMS_PRJ_NAME'
      // };
      const payload = {
        query_select: this.tableQueryForm.query_select,
        query_from: this.tableQueryForm.query_from,
        query_where: this.tableQueryForm.query_where,
        query_orderby: this.tableQueryForm.query_orderby,
        query_key: this.tableQueryForm.query_key,
        query_value: this.tableQueryForm.query_value
      };
      this.loader = true;
      this.$store
        .dispatch('valueset/verifyValueSetQuery', payload)
        .then(response => {
          this.loader = false;
          this.showSelectQueryAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.queryRespMessage = response.data.message;
            const removeSelect = payload.query_select
              .toLowerCase()
              .replace('select', '');
            const tempQuerySelectOptn = removeSelect.trim().split(',');
            this.querySelectionOptions = tempQuerySelectOptn.map(key => {
              return key.slice(key.lastIndexOf(' ') + 1);
            });
          } else {
            this.isSuccess = false;
            this.queryRespMessage = response.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showSelectQueryAlert = true;
          this.isSuccess = false;
          this.queryRespMessage = 'Something went wrong';
        });
    },
    hideAddLookUpModal() {
      this.showAddLookupModal = false;
      this.$emit('hideAddLookUpModal');
    },
    opendDependent() {
      this.dependentModal = true;
      this.getValueSetList();
    },
    clearSearh() {
      this.valueSetName = null;
      this.getValueSetList();
    },
    getValueSetList() {
      this.loader = true;
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        value_set_name: this.valueSetName,
        value_set_type_lookup: null
      };
      this.$store
        .dispatch('valueset/getValueSetList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.valueSetList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.dependentObj = item;
      this.value_Set_Details.parent_value_set_id = item.value_set_hdr_id;
      this.value_Set_Details.dependent = item.value_set_name;
      this.dependentModal = false;
    },
    openParentValueSet(index) {
      this.parentValueSetModal = true;
      this.detailIndex = index;
      this.getParentValueSetList();
    },
    getParentValueSetList() {
      const parentValueSetId = this.value_Set_Details.parent_value_set_id
        ? this.value_Set_Details.parent_value_set_id
        : this.dependentObj.value_set_hdr_id;
      this.$store
        .dispatch('valueset/getParentValueSet', parentValueSetId)
        .then(response => {
          if (response.status === 200) {
            this.parentValueSetList = response.data.data;
          }
        });
    },
    selectParentValueSet(item) {
      this.parentValueSetModal = false;
      this.valueSetDtls[this.detailIndex].parent_code = item.value_code;
      this.valueSetDtls[this.detailIndex].parent_value_set_line_id =
        item.value_set_dtl_id;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
